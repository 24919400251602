import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Dr. David Anderson - Illinois Vision Care Digital Portal" />
    <h1>llinois Vision Digital Portal</h1>
    <p></p>
    <p></p>
    <div style={{ maxWidth: `400px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <br />
    
  </Layout>
)

export default IndexPage
